(function () {
	'use strict';

	// --- Header ---
	const LayoutHeader = (() => {

		class LayoutHeaderCore {

		    constructor(item) {
		        
		        this.item = item || false;

		        if (this.item !== false) {

			        this.hamburger = this.item.querySelector('.header__hamburger');
			        this.container = this.item.querySelector('.header__container');


			        this.event();	

		        }

		    }


		    event() {

		    	this.hamburger.addEventListener('click', this.event_hamburger.bind(this), false);

		    }


		    event_hamburger() {

		    	this.hamburger.classList.toggle('active');
		    	this.container.classList.toggle('show');

		    	document.body.classList.toggle('overflow-hidden');

		    }

		}


		// init
		const init = () => {

			let header = document.querySelector('.header');			
			

			new LayoutHeaderCore(header);

		};   


		return { init };

	})();


	window.LayoutHeader = LayoutHeader;
	// --- /Header ---

	// --- Lazy loading img ---
	const BasicLazyLoadingImg = (() => {

		class BasicLazyLoadingImgCore {

		    constructor(item) {
		        
		        this.item = item;	       


		        if ('loading' in HTMLImageElement.prototype) { 
				    
				    this.item.forEach(img => { 

				    	img.src = img.dataset.lazySrc; 
				    	img.onload = () => { img.classList.add('loaded'); };
				    
				    });

				} else {

				   	this.event();
					this.lazyLoading();
					
				}
		        		    	
		    }


			event() {
					
				window.addEventListener('scroll', this.lazyLoading.bind(this), false);

			}


			lazyLoading() {
				
				this.item.forEach((img, index, array) => {

					if (window.outerHeight > Math.abs(img.getBoundingClientRect().top) && img.hasAttribute('src') == false) {

						let dataAttr = img.dataset.lazySrc;

						img.src = dataAttr;
						img.removeAttribute('data-lazy-src');
						img.onload = () => { img.classList.add('loaded'); };

						array.splice(index, 1);

					}				


					// если изображений с ленивой загрузкой больше на странице нет, то остановим обработчик.
					if(array.length == 0) event.stopPropagation();

				});

			}
		
		}


		// init
		const init = (item) => {

			let images = Array.from(document.querySelectorAll(item || '[data-lazy-src]'));			
			

			new BasicLazyLoadingImgCore(images);
		
		};   


		return { init };

	})();


	window.BasicLazyLoadingImg = BasicLazyLoadingImg;
	// --- /Lazy loading img ---

	// layout


	// init
	document.addEventListener('DOMContentLoaded', function() {
		
		// layout	
		LayoutHeader.init(); // header

		// others
		BasicLazyLoadingImg.init(); // lazy loading images
			
	});

})();
