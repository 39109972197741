// layout
import { LayoutHeader } from './modules/layout/header.js';

// others
import { BasicLazyLoadingImg } from './modules/others/lazyLoadingImg.js';


// init
document.addEventListener('DOMContentLoaded', function() {
	
	// layout	
	LayoutHeader.init(); // header

	// others
	BasicLazyLoadingImg.init(); // lazy loading images
		
});