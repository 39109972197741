// --- Lazy loading img ---
const BasicLazyLoadingImg = (() => {

	class BasicLazyLoadingImgCore {

	    constructor(item) {
	        
	        this.item = item;	       


	        if ('loading' in HTMLImageElement.prototype) { 
			    
			    this.item.forEach(img => { 

			    	img.src = img.dataset.lazySrc; 
			    	img.onload = () => { img.classList.add('loaded'); };
			    
			    });

			} else {

			   	this.event();
				this.lazyLoading();
				
			}
	        		    	
	    }


		event() {
				
			window.addEventListener('scroll', this.lazyLoading.bind(this), false);

		}


		lazyLoading() {
			
			this.item.forEach((img, index, array) => {

				if (window.outerHeight > Math.abs(img.getBoundingClientRect().top) && img.hasAttribute('src') == false) {

					let dataAttr = img.dataset.lazySrc;

					img.src = dataAttr;
					img.removeAttribute('data-lazy-src');
					img.onload = () => { img.classList.add('loaded'); };

					array.splice(index, 1);

				}				


				// если изображений с ленивой загрузкой больше на странице нет, то остановим обработчик.
				if(array.length == 0) event.stopPropagation();

			})

		}
	
	}


	// init
	const init = (item) => {

		let active = null,
			images = Array.from(document.querySelectorAll(item || '[data-lazy-src]'));			
		

		active = new BasicLazyLoadingImgCore(images);
	
	}   


	return { init };

})()


window.BasicLazyLoadingImg = BasicLazyLoadingImg;


export  { BasicLazyLoadingImg };
// --- /Lazy loading img ---