// --- Header ---
const LayoutHeader = (() => {

	class LayoutHeaderCore {

	    constructor(item) {
	        
	        this.item = item || false;

	        if (this.item !== false) {

		        this.hamburger = this.item.querySelector('.header__hamburger');
		        this.container = this.item.querySelector('.header__container');


		        this.event();	

	        }

	    }


	    event() {

	    	this.hamburger.addEventListener('click', this.event_hamburger.bind(this), false);

	    }


	    event_hamburger() {

	    	this.hamburger.classList.toggle('active');
	    	this.container.classList.toggle('show');

	    	document.body.classList.toggle('overflow-hidden');

	    }

	}


	// init
	const init = () => {

		let active = null,
			header = document.querySelector('.header');			
		

		active = new LayoutHeaderCore(header);

	}   


	return { init };

})()


window.LayoutHeader = LayoutHeader;


export  { LayoutHeader };
// --- /Header ---