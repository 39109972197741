$(document).ready(function(){

// sliders
	$('.slider').slick({
		arrows:false,
		autoplay: false,
		variableWidth:false,

        responsive: [
            {
                breakpoint: 2048,
                settings: "unslick"
            },
            {
               breakpoint: 991,
               settings: {
                  slidesToShow: 3,
                  dots: true
                }
            },
            {
               breakpoint: 767,
               settings: {
                  slidesToShow: 2,
                  dots: true
                }
            },
            {
               breakpoint: 575,
               settings: {
                  slidesToShow: 1,
                  dots: true,
                  centerMode: true,
                  centerPadding: '10%'
                }
            },
        ]        
	});

    //--------------------------

$('.clients_slider').slick({
    arrows:false,
    slidesToShow: 2,
    autoplay: false,
    variableWidth:false,
    responsive: [
            {
                breakpoint: 2048,
                settings: "unslick"
            },
            {
               breakpoint: 991,
               settings: {
                  slidesToShow: 3,
                  dots: true
                }
            },
            {
               breakpoint: 860,
               settings: {
                  slidesToShow: 2,
                  dots: true
                }
            },
            {
               breakpoint: 575,
               settings: {
                  slidesToShow: 1,
                  dots: true,
                }
            },
            
        ]        
    });

$('.burger').click(function(event){
        $('.burger, .nav').toggleClass('active');
        $('body').toggleClass('lock');
    });
    // $('.top_menu').click(function(event){
    //     $('.burger, .top_menu').removeClass('active');
    //     $('body').removeClass('lock');
    // });
//--------------------------------------
    //Объявляем переменные
const parent_original = document.querySelector('.header_top');
const parent = document.querySelector('.nav');
const item = document.querySelector('.header_top_right');

//Слушаем изменение размера экрана
if(item) window.addEventListener('resize', move);

//Функция
function move(){
    const viewport_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewport_width <= 992) {
        if (!item.classList.contains('done')) {
            parent.insertBefore(item, parent.children[2]);
            item.classList.add('done');
        }
    } else {
        if (item.classList.contains('done')) {
            parent_original.insertBefore(item, parent_original.children[2]);
            item.classList.remove('done');
        }
    }
}
//Вызываем функцию
if(item) move();

//-------------------------------------

//Объявляем переменные
const parent_original1 = document.querySelector('.footer_left');
const parent1 = document.querySelector('.nav');
const item1 = document.querySelector('.footer_copy');

//Слушаем изменение размера экрана
if(item1) window.addEventListener('resize', move1);

//Функция
function move1(){
    const viewport_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewport_width <= 992) {
        if (!item1.classList.contains('done')) {
            parent1.insertBefore(item1, parent1.children[2]);
            item1.classList.add('done');
        }
    } else {
        if (item1.classList.contains('done')) {
            parent_original1.insertBefore(item1, parent_original1.children[2]);
            item1.classList.remove('done');
        }
    }
}
//Вызываем функцию
if(item1) move1();

//-----------------------------

//Объявляем переменные
const parent_original2 = document.querySelector('.footer_left');
const parent2 = document.querySelector('.nav');
const item2 = document.querySelector('.footer_conf');

//Слушаем изменение размера экрана
if(item2) window.addEventListener('resize', move2);

//Функция
function move2(){
    const viewport_width = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (viewport_width <= 992) {
        if (!item2.classList.contains('done')) {
            parent2.insertBefore(item2, parent2.children[2]);
            item2.classList.add('done');
        }
    } else {
        if (item2.classList.contains('done')) {
            parent_original2.insertBefore(item2, parent_original2.children[2]);
            item2.classList.remove('done');
        }
    }
}
//Вызываем функцию
if(item2) move2();

});